import { mapState, mapActions } from "vuex";
import Web3 from "web3";

export default {
    data: function() {
        return {
          hasKlaytnAddr: false,
          hasEthereumAddr: false,
        };
    },
    computed: {
      ...mapState("auth", ["me"]),
      ...mapState("coin",["eth","klay","matic"]),
      getKlaytnWalletProvider() {
        const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
        const walletProvider = this.me.klaytnProvider || walletInfo.walletProvider ;
  
        return walletProvider;
      },
      getEthereumWalletProvider() {
        const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
        const walletProvider = this.me.ethereumProvider || walletInfo.walletProvider ;
  
        return walletProvider;
      },
      getPolygonWalletProvider() {
        const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
        const walletProvider = this.me.polygonProvider || walletInfo.walletProvider ;

        return walletProvider;
      },
    },
    methods: {
      ...mapActions("auth", ["verifyTokenCallback"]),
      getCachedSession(){
        const local = localStorage ? localStorage.getItem("walletconnect") : null;
        let session = null;
        if (local) {
          session = JSON.parse(local);
        }
        return session;
      },
      checkKlaytnAddr() {
        return new Promise((resolve, reject) => {
          if (this.isAuthenticated) {
            this.verifyTokenCallback()
              .then(() => {
                if (
                  this.me === undefined ||
                  this.me === null ||
                  this.me.idx === undefined ||
                  this.me.idx === null ||
                  this.me.idx === ""
                ) {
                  // 로그인
                  this.hasKlaytnAddr = false;
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("stake.requiredLogin"),
                    show: true,
                    fontSize: "sm"
                  })
                } else {
                  if (this.me.klaytnAddr === undefined || this.me.klaytnAddr === null || this.me.klaytnAddr === "") {
                    this.hasKlaytnAddr = false;
                    this.$store.commit("setCommonAlertModalInfo", {
                      msgHtml: this.$t("wallet.validation.requiredKlaytn"),
                      show: true,
                      fontSize: "sm"
                    })
                  } else {
                    this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);
                    this.hasKlaytnAddr = true;
                  }
                }
                resolve(this.hasKlaytnAddr);
              })
          } else {
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("stake.requiredLogin"),
              show: true,
              fontSize: "sm"
            })
            reject(this.hasKlaytnAddr);
          }
        })
      },
      checkEthereumAddr() {
        return new Promise((resolve, reject) => {
          if (this.isAuthenticated) {
            this.verifyTokenCallback()
              .then(() => {
                if (
                  this.me === undefined ||
                  this.me === null ||
                  this.me.idx === undefined ||
                  this.me.idx === null ||
                  this.me.idx === ""
                ) {
                  // 로그인
                  this.hasEthereumAddr = false;
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("stake.requiredLogin"),
                    show: true,
                    fontSize: "sm"
                  })
                } else {
                  if (this.me.ethereumAddr === undefined || this.me.ethereumAddr === null || this.me.ethereumAddr === "") {
                    this.hasEthereumAddr = false;
                    this.$store.commit("setCommonAlertModalInfo", {
                      msgHtml: this.$t("wallet.validation.requiredEthereum"),
                      show: true,
                      fontSize: "sm"
                    })
                  } else {
                    this.getPaceBalance();
                    this.hasEthereumAddr = true;
                  }
                }
                resolve(this.hasEthereumAddr);
              })
          } else {
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("stake.requiredLogin"),
              show: true,
              fontSize: "sm"
            })
            reject(this.hasEthereumAddr);
          }
        })
      },
      // Wallet Check >>>
      checkKaikas() {
        if (window.klaytn === undefined || !window.klaytn) {
          alert(this.$t("wallet.validation.notFoundKaikas"));
          return false;
        }

        const kaikas = window.klaytn;

        if (
            kaikas.selectedAddress !== undefined &&
            kaikas.selectedAddress !== null &&
            kaikas.selectedAddress !== ""
        ) {
          if (kaikas.selectedAddress.toLowerCase() !== this.me.klaytnAddr.toLowerCase()) {
            alert(this.$t("wallet.validation.checkWalletAddr"));
            return false;
          }
        } else {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }

        if (
            kaikas.networkVersion === undefined ||
            kaikas.networkVersion === null ||
            kaikas.networkVersion.toString() !== process.env.VUE_APP_KLAYTN_CHAIN_ID
        ) {
          alert(this.$t("wallet.validation.checkNetwork"));
          return false;
        }
        return true;
      },
      async checkMetamaskWithNetwork(network, address) {
        if (window.ethereum === undefined || !window.ethereum) {
          alert(this.$t("wallet.validation.checkMetamask"));
          return false;
        }

        const metamask = window.ethereum;
        const accounts = await window.ethereum.enable();
        let account = accounts.length > 0 ? accounts [0] : null;

        if(account !== null ) {
          if(account.toLowerCase() !== address.toLowerCase()) {
            alert(this.$t("wallet.validation.checkWalletAddr"));
            return false;
          }
        } else {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }

        const chainId = this.getChainId(network)

        if (
            metamask.networkVersion === undefined ||
            metamask.networkVersion === null ||
            metamask.networkVersion.toString() !== chainId
        ) {
          metamask.request({
            method: 'wallet_switchEthereumChain',
            params: [{
              chainId: Web3.utils.toHex(chainId)
            }]
          }).then(data => {
            metamask.request({ method: 'eth_requestAccounts' });
          }).catch(error => {
            if (error.code === 4902) {
              let params = this.getParamsByChainId(chainId);
              window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [params]
              }).then(data => {
                metamask.request({ method: 'eth_requestAccounts' });
              }).catch(error => {
                console.log(error)
                return false;
              })
            }
          })
        }
        return true;
      },
      getChainId(network){
        return ({
          'ethereum': process.env.VUE_APP_ETHEREUM_CHAIN_ID,
          'polygon': process.env.VUE_APP_POLYGON_CHAIN_ID,
        })[network.toLowerCase()] ?? process.env.VUE_APP_ETHEREUM_CHAIN_ID
      },
      getParamsByChainId(chainId){
        // Metamask 네트워크 추가시 필요한 정보
        if(chainId === 56 || chainId === '56' || chainId === '0x38'){
          //BNB Chain
          return {
            chainId: '0x38',
            chainName: 'Binance Smart Chain',
            nativeCurrency: {
              name: 'Binance Coin',
              symbol: 'BNB',
              decimals: 18
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com']
          };
        }else if(chainId === 80001 || chainId === '80001' || chainId === '0x13881') {
          // POLYGON TESTNET
          return {
            chainId: '0x13881',
            chainName: 'Mumbai Test Network',
            nativeCurrency: {
              name: 'Polygon Coin',
              symbol: 'MATIC',
              decimals: 18
            },
            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
            blockExplorerUrls: ['https://mumbai.polygonscan.com/']
          }
        } else if(chainId === 137 || chainId === '137' || chainId === '0x89'){
          // POLYGON MAINNET
          return {
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
              name: 'Polygon Coin',
              symbol: 'MATIC',
              decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com'],
            blockExplorerUrls: ['https://polygonscan.com/']
          }
        }
      },
    }
};
